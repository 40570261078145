import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CodeName } from "@/assets/commontype/CodeName";

import { TagData, ErrorInfoData } from '@/store/settingtags/settingTagsCommon'
import * as settingTagsRetrieve from "@/assets/apitype/settingTagsRetrieve";
import { SettingTagsColRowModel, colDataType } from "@/components/settingtags/SettingTagsTableModel";
import * as compareUtil from "@/util/compareUtil";
import * as arrayutil from "@/util/arrayUtil";
import * as editorUtil from "@/util/editorUtil";

//編集中判定
export const isEditedRowData = (data: TagData): boolean => {
  if (!data) {
    return false;
  }

  return (
    !data.oldValid != !data.valid ||
    !data.oldDelete != !data.delete ||
    data.oldTag != data.tag ||
    ((data.valid || data.tag) && data.oldForegroundColor != data.foregroundColor) ||
    ((data.valid || data.tag) && data.oldBackgroundColor != data.backgroundColor)
  );
}

export interface EditCellInfo {
  row: number,
  col: number,
  rowKey: string,
  value: string | number | object | null,
  relatedValues?: { key: string, value: string | number | object | null }[],
}

//Page State
export type SettingTagsTmpState = {
  progress: Record<string, unknown>,
  retrievedParam: settingTagsRetrieve.RequestParam,  //検索条件(検索済み)

  datas: TagData[],
  rows: any[][],
  tableVer_updateData: number,
  tableVer_updateData_rows: number[],
  tableVer_updateSettting: number,

  errorMessage: string | null,
  infoMessage: string | null,

  editing: boolean,
  functionKeys: string[],
};

export const initialState: SettingTagsTmpState = {
  progress: {},
  retrievedParam: null,

  datas: [],
  rows: [],
  tableVer_updateData: 0,
  tableVer_updateData_rows: [],
  tableVer_updateSettting: 0,

  errorMessage: null,
  infoMessage: null,

  editing: false,
  functionKeys: [],
};

//Page Slice
export type SettingTagsTmpReducer = {
  putProgress: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
  removeProgress: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
  setErrorMessage: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
  setInfoMessage: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
  setEditingStart: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
  setEditingEnd: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
  setRetrievedParam: (state: SettingTagsTmpState, action: PayloadAction<settingTagsRetrieve.RequestParam>) => void,
  searched: (state: SettingTagsTmpState, action: PayloadAction<{ tags: TagData[], colRowModel: SettingTagsColRowModel, }>) => void,
  editRowDatas: (state: SettingTagsTmpState, action: PayloadAction<{ colRowModel: SettingTagsColRowModel, editDatas: EditCellInfo[] }>) => void,
  addRow: (state: SettingTagsTmpState, action: PayloadAction<{ rowCount: number, colRowModel: SettingTagsColRowModel }>) => void,
  removeEmptyRow: (state: SettingTagsTmpState, action: PayloadAction<{ colRowModel: SettingTagsColRowModel }>) => void,
  setFunctionKey: (state: SettingTagsTmpState, action: PayloadAction<string>) => void,
}

const createReducerContent = (): SettingTagsTmpReducer => {
  return {
    putProgress(state: SettingTagsTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: SettingTagsTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    setErrorMessage(state: SettingTagsTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    setInfoMessage(state: SettingTagsTmpState, action: PayloadAction<string>) {
      state.infoMessage = action.payload;
    },
    setEditingStart(state: SettingTagsTmpState) {
      console.log('store.setEditingStart');
      state.editing = true;
    },
    setEditingEnd(state: SettingTagsTmpState) {
      console.log('store.setEditingEnd');

      state.editing = false;

      state.progress = {};
      state.retrievedParam = null;

      state.datas = [];
      state.rows = [];

      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)

    },
    setRetrievedParam(state: SettingTagsTmpState, action: PayloadAction<settingTagsRetrieve.RequestParam>) {
      state.retrievedParam = action.payload;
    },

    searched(state: SettingTagsTmpState, action: PayloadAction<{ tags: TagData[], colRowModel: SettingTagsColRowModel, }>) {
      const setOldData = (data: TagData) => {
        data.oldValid = data.valid !== undefined ? data.valid : null;
        data.oldDelete = data.delete !== undefined ? data.delete : null;
        data.oldTag = data.tag ? data.tag : null;
        data.oldForegroundColor = data.foregroundColor ? data.foregroundColor : null;
        data.oldBackgroundColor = data.backgroundColor ? data.backgroundColor : null;
      }
      const colRowModel = action.payload.colRowModel;

      let tags = parseDataInfoData(action.payload.tags);
      //編集前のデータをセット
      tags.forEach(data => setOldData(data));
      tags = checkDatas(tags); //データチェック

      const rowCount = 10;

      let newDatas: TagData[] = [...tags];
      for (let i = 0; i < rowCount; i++) {
        const data: TagData = {};
        newDatas.push(data);
      }

      //store更新
      state.datas = newDatas;
      state.rows = convertRows(newDatas, colRowModel.colKeys);//, listSortOrder, listSortOrderDesc);

      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    editRowDatas(state: SettingTagsTmpState, action: PayloadAction<{ colRowModel: SettingTagsColRowModel, editDatas: EditCellInfo[] }>) {
      console.log('store.editRowDatas');

      const colRowModel = action.payload.colRowModel;
      const editDatas = action.payload.editDatas;
      const newTagDatas = [...state.datas];
      const newRows = state.rows;

      let editedRows: Set<number> = new Set<number>();
      editDatas.forEach((editData) => {
        const tagData: TagData = newTagDatas[editData.row];
        if (!tagData) {
          return;
        }

        const colKey: string = colRowModel.keyFromCol(editData.col);
        const dataType = colDataType[colKey];
        const value =
          editorUtil.parseValue(editData.value, dataType.type,
            dataType.type == 'numeric' ? dataType.numericFormat.pattern :
              dataType.type == 'date' ? dataType.dateFormat :
                dataType.type == 'time' ? dataType.timeFormat :
                  null);

        newTagDatas[editData.row][colKey] = value;
        editedRows.add(editData.row);
        newTagDatas[editData.row] = checkData(newTagDatas[editData.row]);
      });

      if (editedRows.size > 0) {
        const tableVer_updateData_rows = [];
        editedRows.forEach(row => {
          tableVer_updateData_rows.push(row);
          newRows[row] = convertRow(newTagDatas[row], colRowModel.colKeys);
        });

        state.tableVer_updateData_rows = tableVer_updateData_rows;
        state.tableVer_updateData = state.tableVer_updateData + 1;  //カウントアップしテーブルを再描写対象とする (updateData)
      }
    },
    addRow(state: SettingTagsTmpState, action: PayloadAction<{ rowCount: number, colRowModel: SettingTagsColRowModel }>) {
      console.log('store.addRow');
      const rowCount = action.payload.rowCount;
      const colRowModel = action.payload.colRowModel;

      const newDatas: TagData[] = [];
      for (let i = 0; i < rowCount; i++) {
        const data: TagData = {};
        newDatas.push(data);
      }

      const datas = [...state.datas, ...newDatas];

      state.datas = datas;
      state.rows = convertRows(datas, colRowModel.colKeys);//, listSortOrder, listSortOrderDesc);

      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    removeEmptyRow(state: SettingTagsTmpState, action: PayloadAction<{ colRowModel: SettingTagsColRowModel }>) {
      console.log('store.removeEmptyRow');
      const colRowModel = action.payload.colRowModel;

      let datas = [...state.datas];
      datas = datas.filter(data => data.edited || data.tag);

      //0行になる場合は1行足す
      if (datas.length == 0) {
        const rowCount = 1;
        let newDatas: TagData[] = [];
        for (let i = 0; i < rowCount; i++) {
          const data: TagData = {};
          newDatas.push(data);
        }

        datas = [...datas, ...newDatas];
      }

      state.datas = datas;
      state.rows = convertRows(datas, colRowModel.colKeys);//, listSortOrder, listSortOrderDesc);

      state.tableVer_updateSettting = state.tableVer_updateSettting + 1;  //カウントアップしテーブルを再構築対象とする (updateSetting)
    },
    setFunctionKey(state: SettingTagsTmpState, action: PayloadAction<string>) {
      state.functionKeys = action.payload ? [action.payload] : [];
    },
  }
};

const parseDataInfoData = (datas: TagData[]): TagData[] => {
  datas = [...datas];
  datas.forEach((data) => {
    if (typeof data.valid === 'string') data.valid = data.valid === 'true';
    if (typeof data.delete === 'string') data.delete = data.delete === 'true';
  });
  return datas;
}

//チェック
const checkDatas = (datas: TagData[]): TagData[] => {
  console.log('store.checkDatas');
  return datas.map(data => checkData(data));
}
const checkData = (data: TagData): TagData => {
  //編集状態をセットする
  // Object.keys(data).forEach(key => {
  data.edited = isEditedRowData(data);
  // });

  const errorInfo: ErrorInfoData = {};
  if (!data.errorInfo) {
    data.errorInfo = {};
  }

  if (data.valid) {
    if (!data.tag) {
      errorInfo.tag = '入力してください';
    }
  }

  data.errorInfo = errorInfo;

  return data;
}

//配列データに変換
const convertRows = (datas: TagData[], colKeys: string[]): any[][] => {
  return datas.map((data) => convertRow(data, colKeys));
}
const convertRow = (data: TagData, colKeys: string[]): any[] => {
  const row = [];
  colKeys.forEach((colKey) => {
    row.push(data[colKey]);
  });
  return row;
}

export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}

const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: createReducerContent(),
});

//Page Slice Export
export const settingTagsTmpSlice = createSliceContent("settingTagsTmp");
