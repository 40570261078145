import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as arrayUtil from "@/util/arrayUtil";
import { SettingTagsColRowModel, settingTagsColRowModelDefault } from "@/components/settingtags/SettingTagsTableModel";

//強制表示列
export const mustVisibleColumnsKey: string[] = [
  "no",
  //入力項目
  "valid",
  "delete",
  'tag',
  'foregroundColor',
  'backgroundColor',
  'preview',
];
//デフォルト表示列
export const defaultVisibleColumnsKey: string[] = [
];

//Page State
export type SettingTagsSaveState = {
  //検索条件
  refreshOptions_ver: number,

  hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],

  colWidthsModified: {},
};

export const initialSettingTagsState: SettingTagsSaveState = {
  refreshOptions_ver: 0,

  hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,

  colWidthsModified: {},

};

//Page Slice
export type SettingTagsSaveReducer = {
  initOnDidMount: (state: SettingTagsSaveState) => void,
  resetOnWillUnmount: (state: SettingTagsSaveState) => void,

  refreshOptions: (state: SettingTagsSaveState) => void,

  columnWidthChange: (state: SettingTagsSaveState, action: PayloadAction<{ colKey: string, width: number }>) => void,
  hideColumnKeysChange: (state: SettingTagsSaveState, action: PayloadAction<string[]>) => void,
}

const createReducerContent = (name: string, colRowModel: SettingTagsColRowModel, initialState: SettingTagsSaveState): SettingTagsSaveReducer => {
  return {
    //componentDidMount
    initOnDidMount(state: SettingTagsSaveState) {
      //初期表示列0配列は、常に全項目表示とする
      if (initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colRowModel.colKeys;
      }
      else if (!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
      //強制選択列
      state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colRowModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
      //非表示列
      state.hiddenColumnsKey = arrayUtil.not(colRowModel.colKeys, state.visibleColumnsKey);

      if (state.colWidthsModified === undefined) {
        state.colWidthsModified = initialState.colWidthsModified;
      }

    },
    //componentWillUnmount
    resetOnWillUnmount(state: SettingTagsSaveState) {
      //初期表示列0配列は、常に全項目表示とするため、記憶しない
      if (initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = [];
      }
    },
    refreshOptions(state: SettingTagsSaveState) {
      state.refreshOptions_ver = state.refreshOptions_ver + 1;
    },
    columnWidthChange(state: SettingTagsSaveState, action: PayloadAction<{ colKey: string, width: number }>) {
      console.log('store.columnWidthChange');
      const colKey = action.payload.colKey;
      const width = action.payload.width;
      if (colKey && width) {
        state.colWidthsModified[colKey] = width;
      }
    },
    hideColumnKeysChange(state: SettingTagsSaveState, action: PayloadAction<string[]>) {
      console.log('store.hideColumnKeysChange');
      //store更新
      state.hiddenColumns = colRowModel.colFromKeys(action.payload);
      state.hiddenColumnsKey = action.payload;
      state.visibleColumnsKey = arrayUtil.not(colRowModel.colKeys, action.payload);
    },
  }
};

const createSliceContent = (name: string, colRowModel: SettingTagsColRowModel, initialState: SettingTagsSaveState) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colRowModel, initialState),
});

//Page Slice Export
export const settingTagsSaveSlice = createSliceContent("settingTagsSave", settingTagsColRowModelDefault, initialSettingTagsState);
